
import "./custom.scss"
import "./App.css";
import Main from "./components/Main";

function App() {
  return (
    <div className="App">
      <Main name="Jesse" />
    </div>
  );
}

export default App;
